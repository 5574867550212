import { commonWorkflowService, ecrPrerequisitesService } from '@/utils/apis'
import {
  type EcrPrerequisitesRequestDto,
  EcrProposalRequest,
  type EcrProposalRequestDto,
  EcrRemovalRequest,
  type EcrRemovalRequestDto,
  type EcrResentNotificationRequestDto,
  FindCeremonyParticipants1Request,
  FindScheduledMeetingRequest,
  ProposedECRsRequest,
  ResendNotificationToEcrRecipientRequest,
  ScheduleCeremony2Request
} from '@/api/origin-workflow-svc'

export const startWorkflow = async (
  ecrPrerequisitesRequestDto: EcrPrerequisitesRequestDto
) => {
  return await ecrPrerequisitesService.start3({
    ecrPrerequisitesRequestDto
  })
}

export async function proposeRecipients(
  ecrProposalRequestDto: EcrProposalRequestDto
) {
  return await ecrPrerequisitesService.ecrProposal({
    ecrProposalRequestDto
  })
}

export async function getProposedEcrRecipientsByWorkflowInstanceId(
  workflow: string
) {
  return ecrPrerequisitesService.proposedECRs({
    workflow
  })
}

export async function getProposedEcrRecipientsByMeetingId(meeting: string) {
  return await ecrPrerequisitesService.proposedECRs({
    meeting
  })
}

export async function scheduleCeremony(
  scheduleCeremonyRequestDto: ScheduleCeremony2Request
) {
  return await ecrPrerequisitesService.scheduleCeremony2(
    scheduleCeremonyRequestDto
  )
}

export async function getCeremonyParticipants(workflow: string) {
  return await ecrPrerequisitesService.findCeremonyParticipants1({
    workflow
  })
}

export async function getCeremonyTime(workflow: string) {
  return await commonWorkflowService.findScheduledMeeting({
    workflow
  })
}

export async function removeECRRecipient(
  ecrRemovalRequestDto: EcrRemovalRequestDto
) {
  return await ecrPrerequisitesService.ecrRemoval({
    ecrRemovalRequestDto
  })
}

export async function resendECRInvitation(
  ecrResentNotificationRequestDto: EcrResentNotificationRequestDto
) {
  return await ecrPrerequisitesService.resendNotificationToEcrRecipient({
    ecrResentNotificationRequestDto
  })
}
