import React from 'react'
import Heading from '@/components/typography/heading'
import { FormattedMessage } from 'react-intl'
import Alert, { MODE } from '@/components/base/Alert'
import Table from '@/components/base/table/Table'
import { Label, Radio } from 'flowbite-react'
import { EcrPrerequisitesRequestDtoEcrWorkflowModeEnum } from '@/api/origin-workflow-svc'
import { EcrWorkflow } from '@/models/ecr-vlei-workflow'
import { isEmpty } from 'lodash'

interface IProps {
  workflow: EcrWorkflow
  onWorkflowModeChange: (
    workflowMode: EcrPrerequisitesRequestDtoEcrWorkflowModeEnum
  ) => void
}

export const Introduction = ({ workflow, onWorkflowModeChange }: IProps) => {
  const disableModeSelection = !isEmpty(workflow)

  const issuanceModeOptions = () => {
    const defaultValue = isEmpty(workflow?.rawData?.args?.workflowMode)
      ? EcrPrerequisitesRequestDtoEcrWorkflowModeEnum.QviIssued
      : (workflow?.rawData?.args
          ?.workflowMode as EcrPrerequisitesRequestDtoEcrWorkflowModeEnum)

    const options = [
      {
        id: 'qvi',
        value: EcrPrerequisitesRequestDtoEcrWorkflowModeEnum.QviIssued,
        label: (
          <FormattedMessage id="page.generic.ecr.workflowMode.option.qvi" />
        ),
        description: (
          <FormattedMessage id="page.generic.ecr.workflowMode.description.qvi" />
        )
      },
      {
        id: 'self',
        value: EcrPrerequisitesRequestDtoEcrWorkflowModeEnum.SelfIssued,
        label: (
          <FormattedMessage id="page.generic.ecr.workflowMode.option.self" />
        ),
        description: (
          <FormattedMessage id="page.generic.ecr.workflowMode.description.self" />
        )
      }
    ]

    return options.map((option, key) => ({
      key: (
        <div className={`flex gap-2 text-justify`}>
          <Radio
            id={option.id}
            name="workflowModel"
            value={option.value}
            className="mt-1"
            disabled={disableModeSelection}
            onChange={(e) =>
              onWorkflowModeChange(
                e.target.value as EcrPrerequisitesRequestDtoEcrWorkflowModeEnum
              )
            }
            defaultChecked={defaultValue === option.value}
          />
          <Label htmlFor={option.id}>
            <p className="text-base">{option.label}</p>
            <p className="text-sm font-normal text-neutralsAdditional800">
              {option.description}
            </p>
          </Label>
        </div>
      )
    }))
  }

  return (
    <>
      <div className="pt-4 pb-6 pl-8 pr-4 border-b border-b-grey200">
        <div className="max-w-[656px] ">
          <Heading className="mb-2">
            <FormattedMessage id="ecr.task.intro.title" />
          </Heading>
          <p className="mb-5">
            <FormattedMessage id="page.generic.ecrGuidance1" />
          </p>
          <p>
            <FormattedMessage id="page.generic.ecrGuidance2" />{' '}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-12  px-8 pt-6 pb-8 ">
        <div className="col-span-12 md:col-span-10 ">
          <Alert
            className="mb-4 mt-0 border-SecondaryBlue50 bg-pressedMenuItem/25"
            mode={MODE.INFO}
          >
            <p className="mb-5 text-base text-hoverBlue">
              <FormattedMessage id="page.generic.ecrVleiGuidance1" />
            </p>
            <p className="text-base text-hoverBlue">
              <FormattedMessage id="page.generic.ecrVleiGuidance2" />
            </p>
          </Alert>

          <Alert className="mb-0" mode={MODE.WARNING}>
            <p>
              <FormattedMessage id="page.generic.ecrRecommendation" />
            </p>
          </Alert>

          {/* TODO: Temporary we are hiding the self issuance. once the DF-2486 is done */}
          {false && (
            <Table
              className={`mt-5 ${disableModeSelection && 'bg-gray-50'}`}
              data={issuanceModeOptions()}
              headerColumns={[
                {
                  label: (
                    <span className="font-medium text-lg text-neutralsProductInkNormal900">
                      <FormattedMessage id="page.generic.ecr.startIssuance" />
                    </span>
                  ),
                  key: 'key'
                }
              ]}
              headerClassName="!bg-white"
            />
          )}
        </div>
      </div>
    </>
  )
}
